import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock7 from "../img/mock7.png"
import mock6 from "../img/mock6.png"
export let colors = ["rgb(152, 230, 250)", "rgb(28, 51, 70)"];


export const info = {
    firstName: "Laurie",
    lastName: "Kim",
    initials: "Laurie",
    position: "a Full Stack Software Engineer",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [

        {
            emoji: '🌎',
            text: 'Based in the US & Canada'
        },
        {
            emoji: "💻",
            text: "Front-end & Back-end"
        },
        {
            emoji: "📧",
            text: "lauriekim01@gmail.com"
        }
    ],
    socials: [
        {
            link: "https://www.linkedin.com/in/lauriekim01/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://gitlab.com/lauriekim01",
            icon: "fa fa-gitlab",
            label: 'gitlab'
        },

    ],
    bio: "Hello! I'm a Full Stack Software Engineer. I worked as a SmartHome AI Technology Study Manager and Web Developer, developed a web app that allows users to report instances of fraud and published scientific research journals on topics such as Telehealth and Robotics. I love to learn and my goal in life is to create technologies that can better the lives of others.",
    skills:
        {
            proficientWith: ['JavaScript', 'Python3', 'HTML5', 'CSS', 'WordPress', 'AWS', 'Figma', 'SQL', 'REACT', 'DOM Manipulation', 'Websockets', 'FastAPI', 'Django', 'RabbitMQ', 'MySQL', 'PostgreSQL', 'MongoDB', 'Git', 'GitLab', 'Bootstrap', 'Microservices', 'Monoliths', 'Agile']

        }
    ,
    hobbies: [
        {
            label: 'Machine Learning/AI',
            emoji: '🤖'
        },
        {
            label: 'Innovative Ideas',
            emoji: '📚'
        },
        {
            label: 'Traveling',
            emoji: '🗺️'
        },
        {
            label: 'Cooking',
            emoji: '🌶'
        }

    ],
    portfolio: [ 
        {
            title: "ViTAA Medical Solutions Website",
            live: "https://vitaamedical.com",
            image: mock6
        },
        {
            title: "The Mike Ferry Organization Website",
            live: "https://mikeferry.com",
            image: mock7
        },
        {
            title: "Frauddit",
            live: "https://team-6-goljira.gitlab.io/frauddit/",
            source: "https://gitlab.com/team-6-goljira/frauddit", 
            image: mock1
        },
        {
            title: "AutoSales",
            live: "https://gitlab.com/lauriekim01/auto-sales",
            source: "https://gitlab.com/lauriekim01/auto-sales",
            image: mock2
        },  
    ]
}